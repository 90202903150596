exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beta-tsx": () => import("./../../../src/pages/beta.tsx" /* webpackChunkName: "component---src-pages-beta-tsx" */),
  "component---src-pages-drip-tsx": () => import("./../../../src/pages/drip.tsx" /* webpackChunkName: "component---src-pages-drip-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-important-notice-tsx": () => import("./../../../src/pages/important-notice.tsx" /* webpackChunkName: "component---src-pages-important-notice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-tsx": () => import("./../../../src/pages/mobile/[...].tsx" /* webpackChunkName: "component---src-pages-mobile-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-token-tsx": () => import("./../../../src/pages/token.tsx" /* webpackChunkName: "component---src-pages-token-tsx" */)
}

