import React, { useState } from 'react';

const UIColor: any = React.createContext(undefined);

export const UIColorProvider = (props: any) => {
  const { children } = props;

  const [color, setColor] = useState(null);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    color,
    setColor,
  };

  return <UIColor.Provider value={context}>{children}</UIColor.Provider>;
};

export const useUIColor = (): any => {
  const context = React.useContext(UIColor);
  if (context === undefined) {
    throw new Error(`useUIColor must be used within a UIColorProvider`);
  }
  return context;
};
